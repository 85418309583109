import {
  Attachment,
  Category,
  CustomField,
  Expense,
  ExportOption,
  NextAction,
  PaginationParam,
  ReportCustomFieldType,
} from 'apis/shared/types';
import { ReportRefundStage, ReportType } from 'apis/models/report';
import { ReportWatcherType, ReportWatcher as ReportWatcherV2, WatchAddableGroup, WatchAddableUser } from './models';

import API from 'apis/API';
import { CancelToken } from 'axios';
import { CompanyUserStatus } from 'apis/CompanyAPI';
import { DecimalNumberString } from 'utils/CurrencyUtil/consts';
import { ExpenseType } from 'apis/ExpenseAPI/models';
import { HttpQueryUtil } from 'components/utility/http_query_util';
import { ISO8601DateFormatString } from 'utils/Date';
import LabelValue from 'types/labelValue';
import { ReportHistory } from '../ReportHistoryAPI/types';
import { get } from 'lodash-es';
import { isAWSSlowDownErrorResponse } from 'utils/ErrorHandler';

/**
 * @deprecated apis > models > report > ReportStage로 이관
 */
export type StageValue = 'ready' | 'sent' | 'refund_completed' | 'approved';

export enum APPROVER_AUTHORITY {
  REFUND_APPROVER = 'refund_approver',
  FINAL_APPROVER = 'final_approver',
  DEFAULT_APPROVER = 'default_approver',
}

export interface ApproverInfo {
  approved: boolean;
  authority: string;
  current: boolean;
  email: string;
  id: number;
  name: string;
}

export interface ReportViolation {
  count: number;
  hide: boolean;
  label: string;
}

export interface ExchangeRate {
  price: string;
  use_custom_rate: boolean;
}

export type ReportApprover = {
  id: number;
  email: string;
  name: string;
  company_name: string;
  thumbnail_url: string | null;
  role: PolicyMemberRole;
} & LabelValue<number>;

export type ReportDetailEditor = {
  templates: EditorTemplate[];
  selected_template: {
    id: number;
    title: string;
    is_default: boolean;
    content: {
      download_url: string;
      is_upload_complete: boolean;
    };
  };
  content: {
    download_url: string | null;
    is_upload_complete: boolean;
  };
};

export type EditorTemplate = {
  id: number;
  title: string;
  is_default: boolean;
  content: {
    download_url: string;
    is_upload_complete: boolean;
  };
};

type ReportTotalPriceByCurrency = {
  currency: string;
  currency_symbol: string;
  total_price: string;
};

export interface Report {
  approver: ReportApprover;
  approver_list: any[];
  approvers_info: ApproverInfo[];
  completed: boolean;
  currency: string;
  custom_fields: CustomField[];
  default_info: any[];
  edit_exchange_rate: boolean;
  editable: string[];
  editor: ReportDetailEditor | null;
  ends_at: string;
  exchange_rates: { [propName: string]: ExchangeRate };
  final_approver: any;
  is_final_approver: boolean;
  first_sent_at: string | null;
  ghost_id: number | null;
  histories: ReportHistory[];
  id: number;
  latest_approved_at: string | null;
  latest_denied_at: string | null;
  latest_sent_at: string | null;
  next_action: NextAction[];
  not_purchased_count: number;
  period_type: boolean;
  plan_info: any;
  planning: boolean;
  policy: { id: number; label: string };
  refund_stage: ReportRefundStage;
  removable: boolean;
  report_type: ReportTypeLabel;
  restrict_submission: boolean;
  sender_info: string;
  stage: { label: string; value: StageValue };
  stage_str: string;
  starts_at: string;
  submit_type: string;
  title: string;
  total_amount: string;
  total_refund_amount: string;
  user: any;
  user_id: number;
  violations: ReportViolation[];
  policy_approval_line_info?: { name: string };
  exceed_amount: string | null;
  total_price_by_currency: ReportTotalPriceByCurrency[];
  exported: ExportType[];
  fail_exported: ExportType[];
  available_actions: ReportAvailableAction[];
  finalized_at?: string;
}

export type ReportAvailableAction = 'remove' | 'share' | 'export' | 'duplicate' | 'print';

type ReportTypeLabel = LabelValue<Exclude<ReportType, null>>;

export interface ReportStage {
  label: string;
  value: string;
}

export type DenyReceiver = {
  email: string;
  id: number;
  label: string;
  name: string;
  owner: boolean;
  role: string;
  thumbnail_url: string | null;
  user_id: number;
  user_status: CompanyUserStatus;
  value: number;
};

export type RelatedReportDataMeta = {
  id: number;
  title: string;
  sent_at?: ISO8601DateFormatString;
  currency: string;
  total_calculated_price: DecimalNumberString;
  user: {
    id: number;
    name: string;
  };
  policy: {
    id: number;
    name: string;
  };
};

export interface ReportMeta {
  attachments: Attachment[];
  categories: Category[];
  currency: string;
  currencies: LabelValue<string>[];
  check_types: LabelValue<string>[];
  deny_receivers: DenyReceiver[];
  export_options: ExportOption[];
  in_policy: boolean;
  locked: string;
  notes: any[];
  policies: any[];
  report_types: ReportTypeLabel[];
  users_with_visibility: string[];
  related_report_data: RelatedReportDataMeta[];
}

export interface ReportReceiptImage {
  image_url: string;
  merchant_name: string;
  currency: string;
  price: number;
}

export interface ReportComment {
  description: string;
  history_type: string;
  id: number;
  thumbnail_url: string;
  updated_at: string;
  user_id: number;
  user_name: string;
}

export interface ReportExpenseGroupReportPlanItem {
  id: number;
  currency: string;
  plan_name: string;
  check_type: string;
  name: string | null;
  limitation_info: {
    total_used_amount: number;
    expense_count_str: string;
    expense_count: number;
    limitation: string;
  };
  descriptions: {
    label: string;
    value: string;
    app_only?: boolean;
  }[];
  is_custom: boolean;
  category_id: number;
}

export interface ReportExpenseGroup {
  expenses: Expense[];
  label: string;
  amount: string;
  query: string;
  page: number;
  refund_amount: number;
  meta: {
    expense_ids: number[];
    page_count: number;
  };
  report_plan_item: ReportExpenseGroupReportPlanItem;
}

export interface AttendeeStats {
  0: { id: number; name: string }[];
  1: {
    price: string;
    attendee_id: number;
    attendee_name: string;
  }[][];
}

export type PostSearchReportsParams = {
  filter: {
    title_or_name: string;
    planning: boolean;
    for_expense: boolean;
    for_expense_expense_type?: ExpenseType;
    report_types?: ReportType[];
  };
  policy_id: number;
  user_id: number;
};

type PostSearchReportsResponse = {
  reports: SearchReport[];
};

/**
 * post /web/search_reports
 */
export const postSearchReports = async (params: PostSearchReportsParams) => {
  const res = await API.post<PostSearchReportsResponse>('/web/search_reports', params);

  return res.data;
};

/**
 * GET /web/report/:id
 * @param id report ID
 * @param param
 */
export const getReport = async (id: string, includeExpense: boolean) => {
  const res = await API.get<{
    attendee_stats: AttendeeStats;
    expense_meta: ReportExpenseMeta;
    meta: ReportMeta;
    report: Report;
  }>(`/web/report/${id}`, {
    params: {
      include_expenses: includeExpense,
    },
  });

  return res.data;
};

export interface PagedReportsResponseReport {
  approver: ReportApprover | null;
  completed: boolean;
  csv_url: string | null;
  currency: string;
  erp_exported: boolean;
  erp_icons: { icon_url: string }[];
  export_icons: { icon_url: string }[];
  exported: string[];
  id: number;
  latest_approved_at: boolean;
  period: string | null;
  plan_status: string;
  planning: boolean;
  policy_approval_line_info: {
    id: number;
    name: string;
    report_type: string;
    limit_type: string;
    completion: boolean;
  };
  policy_approval_line_name: string | null;
  policy_id: number;
  policy_name: string;
  refund_stage: string;
  removable: boolean;
  report_type: ReportTypeLabel;
  sent_at: string;
  stage: ReportStage;
  stage_str: string;
  status: string | null;
  title: string;
  todo: string;
  total_amount: number;
  user_id: number;
  user: {
    label: string;
    value: number;
    id: number;
    email: string;
    name: string;
    company_name: string;
    thumbnail_url: string;
    status: string;
    accessible?: boolean;
  };
  is_watching_report: boolean;
}

interface PagedReportsResponse {
  meta: {
    currency: string;
    current_page: number;
    is_first_page: boolean;
    is_last_page: boolean;
    limit_value: number;
    next_page: number;
    page_amount: string;
    page_count: number;
    prev_page: boolean | null;
    total_count: number;
  };
  reports: PagedReportsResponseReport[];
}

interface PagedReportsParams {
  page?: number;
  count_per_page?: number;
  filter: string;
  order?: string;
}

export interface ReportsMeta {
  has_approval_authority: boolean;
  bulk_approvable: boolean;
  export_options: {
    ile_type: string;
    format: string;
    group: string;
    icon_url: string;
    label: string;
    tax_refund?: boolean;
  }[];
  locked: boolean;
  policies_for_filter: {
    label: string;
    value: number | null;
    budget_policy?: {
      info_showable: boolean;
      over_price_limitable: boolean;
      member_visibility_limitable: boolean;
    };
    disabled?: boolean;
    edit_distance?: boolean;
    id?: number;
    name?: string;
    open_daily_wage?: boolean;
    open_time?: boolean;
    personal?: boolean;
    policy_type?: string;
    report_auto_title?: boolean;
  };
  report_schedule: string;
  report_statuses: number[];
  report_types_for_filter: string[];
  report_types: {
    expense: string[];
    general_approval: string[];
  };
  stages: {
    label: string;
    value: string;
  }[];
}

interface ReportsMetaResponse {
  meta: ReportsMeta;
}

/**
 * Get /web/reports
 * @param params
 */
export const getPagedReports = async (params: PagedReportsParams, cancelToken?: CancelToken) => {
  const res = await API.get<PagedReportsResponse>('/web/reports', { params, cancelToken });
  return res.data;
};

export const getReportsMeta = async () => {
  const res = await API.post<ReportsMetaResponse>('/web/reports/meta');
  return res.data;
};

type PostReportParams = {
  report: string;
  filter: string;
};

type PostReportResponse = {
  report: PagedReportsResponseReport;
};

/**
 * POST /web/report
 */
export const postReport = async (params: PostReportParams) => {
  const res = await API.post<PostReportResponse>('/web/report', params);

  return res.data;
};

export type DeleteReportParams = {
  report_ids: number[];
  policy_id?: number;
  filter?: string;
  checked_all?: boolean;
};

type DeleteReportResponse = {
  ids: number[];
};

/**
 * DELETE /web/report
 */
export const deleteReport = async (params: DeleteReportParams) => {
  const res = await API.delete<DeleteReportResponse>('/web/report', {
    data: params,
  });

  return res.data;
};

const isERPFormat = (format: string) => {
  const erpFormats = ['icube', 'iu', 'sap'];
  return erpFormats.indexOf(format) > -1;
};

const getExportEndpointType = (format: string, fileType: string) => {
  if (isERPFormat(format) && fileType === 'erp_export') {
    return 'erp_download';
  }
  return 'file_download';
};

/**
 * POST /web/report/{:exportType}
 *
 * @description /web/report/erp_download | /web/report/file_download
 */
export const exportReport = async (params: ExportReportParam, cancelToken?: CancelToken) => {
  const res = await API.post(
    `/web/report/${getExportEndpointType(params.format, get(params, 'options.file_type', 'erp_export'))}`,
    {
      policy_id: params.policyId,
      format: params.format,
      id: params.id,
      editor_content: params.editor_content,
      options: JSON.stringify(params.options),
    },
    { timeout: Number(process.env.REACT_APP_DOWNLOAD_API_TIMEOUT), cancelToken }
  );

  return res.data;
};

/**
 * POST /web/reports/{:exportType}
 *
 * @description /web/reports/erp_download | /web/reports/file_download
 */
export const exportReports = async (params: ExportReportsParam) => {
  const res = await API.post(
    `/web/reports/${getExportEndpointType(params.format, get(params, 'options.file_type', 'erp_export'))}`,
    {
      report_ids: params.reportIds,
      checked_all: params.checkedAll,
      format: params.format,
      filter: params.filter,
      options: params.options,
    },
    { timeout: Number(process.env.REACT_APP_DOWNLOAD_API_TIMEOUT) }
  );

  return res.data;
};

/**
 * get /web/report/comments
 * @param params
 */
export const getComments = async (id: number, page: number, countPerPage = 10) => {
  const res = await API.get<{
    comments: ReportComment[];
    page_count: number;
    total_count: number;
  }>('/web/report/comments', { params: { id, page, count_per_page: countPerPage } });

  return res.data;
};

/**
 * POST /web/report/comment
 * @param id report ID
 */
export const saveComment = async (id: number, comment: string) => {
  const res = await API.post('/web/report/comment', { report: JSON.stringify({ comment }), id });
  return res.data;
};

export const deleteComment = async (historyId: number, reportId: number) => {
  const res = await API.delete('/web/report/comment', {
    data: {
      report_history_id: historyId,
      id: reportId,
    },
  });
  return res.data;
};

/**
 * PUT /web/report/comment
 */
export const updateComment = async (reportId: number, historyId: number, description: string) => {
  const res = await API.put('/web/report/comment', {
    description,
    report_history_id: historyId,
    id: reportId,
  });
  return res.data;
};

/**
 * POST /web/report/expenses
 */
export const getReportExpenses = async (
  reportId: number,
  listAll: boolean,
  target: string | null,
  query: string | null,
  paginationParam: PaginationParam
) => {
  const res = await API.post<{ expense_list: ReportExpenseGroup[] } | ReportExpenseGroup>('/web/report/expenses', {
    id: reportId,
    list_all: listAll,
    target,
    query,
    page: paginationParam.page,
    count_per_page: paginationParam.countPerPage,
  });

  return res.data;
};

export const deleteReportPlanItem = async (reportId: number, planId: number) => {
  const res = await API.delete('/web/report_plan_item', {
    data: {
      id: planId,
      report_id: reportId,
    },
  });
  return res.data;
};

/**
 * 사용되는 API path
 * POST /web/report/submit
 * POST /web/report/approve
 * POST /web/report/cancel
 * POST /web/report/complete_refund
 * POST /web/report/refund
 * POST /web/report/cancel_approve
 * POST /web/report/close
 */
export const postAction = async (actionType: ReportActionType, body: any) => {
  const res = await API.post(`/web/report/${actionType}`, HttpQueryUtil.FormData(body));

  return res.data;
};

export const bulkActionUsers = async (
  stage: 'refund' | 'cancel_approve',
  policyId: number,
  isCheckedAll: boolean,
  userIds: number[]
) => {
  const res = await API.post(
    `/web/reports/bulk_action_users`,
    HttpQueryUtil.FormData({
      stage,
      policy_id: policyId,
      checked_all: isCheckedAll,
      user_ids: [...userIds],
    })
  );

  return res.data;
};

export const putReport = async (params: PutReportParam) => {
  const res = await API.put<PutReportResponse>('/web/report', {
    id: params.id,
    report: JSON.stringify({
      report_plan_item_id: params.report.report_plan_item_id,
      attach_expense: {
        checked_all: params.report.attachExpense.checkedAll, // 전체선택 유무 optional
        ids: params.report.attachExpense.ids, // checked_all 이 true 인 경우 지출목록에서 exclude 의미로 사용됨
        filter: params.report.attachExpense.filter,
      },
    }),
  });

  return res.data;
};

/**
 * GET /web/reports/aggregation
 */
export const getReportsAggregation = async (params: GetReportsAggregationParam) => {
  const res = await API.get<GetReportsAggregationResponse>('/web/reports/aggregation', { params });

  return res.data;
};

export type PostCompleteTransferResponse = {
  success: boolean;
};

/**
 * POST /v4/reports/:id/complete_transfer
 */
export const postCompleteTransfer = async (id: number) => {
  const res = await API.post<PostCompleteTransferResponse>(`/v4/reports/${id}/complete_transfer`);

  return res.data;
};

export type GetReportCustomFieldsFilterDataParams = {
  order: SortOption;
};

export type ReportCustomFieldFilterData = {
  field_type: ReportCustomFieldType;
  field_title: string;
};

export type GetReportCustomFieldsFilterDataResponse = {
  report_custom_fields: ReportCustomFieldFilterData[];
};

/**
 * GET /v4/report_custom_fields/filter_data
 */
export const getReportCustomFieldsFilterData = async (params: GetReportCustomFieldsFilterDataParams) => {
  const res = await API.get<GetReportCustomFieldsFilterDataResponse>('/v4/report_custom_fields/filter_data', {
    params,
  });

  return res.data;
};

export type GetReportCustomFieldsFilterInitialValuesParams = {
  field_title: string;
  field_type: ReportCustomFieldType;
  order: SortOption;
  filter?: { initial_value?: string };
};

export type GetReportCustomFieldsFilterInitialValuesResponse = {
  initial_values: string[];
};

/**
 * GET /v4/report_custom_fields/filter/initial_values
 */
export const getReportCustomFieldsFilterInitialValues = async (
  params: GetReportCustomFieldsFilterInitialValuesParams
) => {
  const res = await API.get<GetReportCustomFieldsFilterInitialValuesResponse>(
    '/v4/report_custom_fields/filter/initial_values',
    { params }
  );

  return res.data;
};

export type PostReportsExportErpReserveParams = {
  checked_all: boolean;
  report_ids: number[];
  filter?: string;
};

export type PostReportsExportErpReserveResponse = {
  result: {
    job_id: string;
    success: boolean;
  };
};

/**
 * POST /v4/reports/export/erp_reserve
 */
export const postReportsExportErpReserve = async (params: PostReportsExportErpReserveParams) => {
  const res = await API.post<PostReportsExportErpReserveResponse>('/v4/reports/export/erp_reserve', params);

  return res.data;
};

export type GetReportsJobsResultResponse = {
  job_id: string;
  job_type: 'card_upload' | 'export_report';
  total_reports_count: number;
  success_count: number;
  fails_count: number;
  fails: {
    report_id: number;
    report_title: string;
    message: string;
    detail: string;
  }[];
};

/**
 * GET /v4/reports/jobs/:job_id/result
 */
export const getReportsJobsResult = async (jobId: string) => {
  const res = await API.get<GetReportsJobsResultResponse>(`/v4/reports/jobs/${jobId}/result`);

  return res.data;
};

type PostReportsExportDiffCheckParams = {
  include_ids: number[];
  filter?: Record<string, unknown>;
};

export type PostReportsExportDiffCheckResponse = {
  result: {
    total_count: number;
    first_export_report_count: number;
    different_report_count: number;
    different_report_titles: string[];
    same_report_count: number;
    same_report_titles: string[];
  };
};

/**
 * POST /v4/reports/export/diff_check
 */
export const postReportsExportDiffCheck = async (payload: PostReportsExportDiffCheckParams) => {
  const res = await API.post<PostReportsExportDiffCheckResponse>('/v4/reports/export/diff_check', payload);

  return res.data;
};

type PostReportsExportDiffCheckAllParams = {
  exclude_ids: number[];
  filter?: Record<string, unknown>;
};

/**
 * POST /v4/reports/export/diff_check_all
 */
export const postReportsExportDiffCheckAll = async (payload: PostReportsExportDiffCheckAllParams) => {
  const res = await API.post<PostReportsExportDiffCheckResponse>('/v4/reports/export/diff_check_all', payload);

  return res.data;
};

export type PostReportsDuplicateResponse = {
  result: {
    report_id: number;
  };
};

/**
 * POST /v4/reports/:id/duplicate
 */
export const postReportsDuplicate = async (reportId: number) => {
  const res = await API.post<PostReportsDuplicateResponse>(`/v4/reports/${reportId}/duplicate`);

  return res.data;
};

type PutReportExpensesTransferParams = {
  include_ids: number[];
  report_plan_item_id: number;
};

type PutReportExpensesTransferResponse = {
  result: boolean;
};

/**
 * PUT /v4/reports/:id/expenses/transfer_and_attach
 */
export const putReportExpensesTransfer = async (reportId: number, params: PutReportExpensesTransferParams) => {
  const res = await API.put<PutReportExpensesTransferResponse>(
    `/v4/reports/${reportId}/expenses/transfer_and_attach`,
    params
  );

  return res.data;
};

export type GetReportPlanItemResponseReportPlanItem = {
  id: number;
  attendee_ids: number[];
  category: Category | null;
  is_custom: boolean;
  limitation: string;
  description: string | null;
  currency: LabelValue<number>;
  check_type: LabelValue<string>;
  descriptions: {
    app_only: boolean;
    label: string;
    value: string;
  }[];
};

export type GetReportPlanItemResponse = {
  report_plan_item: GetReportPlanItemResponseReportPlanItem;
};

/**
 * GET /web/report_plan_item
 */
export const getReportPlanItem = async (reportPlanItemId: number) => {
  const res = await API.get<GetReportPlanItemResponse>('/web/report_plan_item', {
    params: {
      id: reportPlanItemId,
    },
  });

  return res.data;
};

export type GetReportPlanItemsResponse = {
  plans: (LabelValue<number> & { id: number })[];
};

/**
 * GET /web/report_plans
 */
export const getReportPlanItems = async (reportId: number) => {
  const res = await API.get<GetReportPlanItemsResponse>('/web/report_plans', {
    params: {
      report_id: reportId,
    },
  });

  return res.data;
};

export type PutReportPlanItemParams = {
  categoryId: number;
  currency: string;
  planCheckType: string;
  memo: string;
  budgetPrice: string;
  reportId: number;
};

/**
 * POST /web/report_plan_item/custom
 */
export const postReportPlanItem = async (params: PutReportPlanItemParams) => {
  const res = await API.post('/web/report_plan_item/custom', {
    report_id: params.reportId,
    report_plan_item: {
      report_id: params.reportId,
      description: params.memo,
    },
    fixed_plan_item: {
      category_id: params.categoryId,
      currency: params.currency,
      check_type: params.planCheckType,
      limitation: params.budgetPrice,
    },
  });

  return res.data;
};

/**
 * PUT /web/report_plan_item
 */
export const putReportPlanItem = async (reportPlanItemId: number, params: PutReportPlanItemParams) => {
  const res = await API.put('/web/report_plan_item', {
    id: reportPlanItemId,
    report_plan_item: {
      report_id: params.reportId,
      description: params.memo,
    },
    fixed_plan_item: {
      category_id: params.categoryId,
      currency: params.currency,
      check_type: params.planCheckType,
      limitation: params.budgetPrice,
    },
  });

  return res.data;
};

/**
 * POST /web/report_plan_items
 */
export const postReportPlanItems = async (reportId: number, planId: number) => {
  const res = await API.post('/web/report_plan_items', {
    report_id: reportId,
    plan_id: planId,
  });

  return res.data;
};

interface PostReportsERPDownloadParams {
  format: 'iu' | 'icube';
}

export interface ReportsERPDownloadError {
  code: string;
  message: string;
  extra_data?: {
    [x: string]: string;
  };
}

export interface PostReportsERPDownloadResponse {
  errors: ReportsERPDownloadError[];
  report: { title: string };
}

/**
 * POST /v4/reports/:id/erp_download
 */
export const postReportsERPDownload = async (reportId: number, params: PostReportsERPDownloadParams) => {
  const res = await API.post<PostReportsERPDownloadResponse>(`/v4/reports/${reportId}/erp_download`, {
    format: params.format,
  });

  return res.data;
};

export interface PostReportsERPReportIdsParams {
  checkedAll: boolean;
  format?: ExportType;
  filter: string | null;
  reportIds: number[];
}

interface PostReportsERPReportIdsResponse {
  report_ids: number[];
}

/**
 * POST /web/reports/erp_report_ids
 */
export const postReportsERPReportIds = async (params: PostReportsERPReportIdsParams) => {
  const res = await API.post<PostReportsERPReportIdsResponse>('/web/reports/erp_report_ids', {
    checked_all: params.checkedAll,
    format: params.format,
    filter: params.filter,
    report_ids: params.reportIds,
  });

  return res.data;
};

export interface PutReportExpensesParams {
  id: number;
  expenseParams: {
    changes: {
      payment?: LabelValue<number>;
      category?: LabelValue<number>;
      tag_ids?: {
        parent_id: number;
        id?: number | null | undefined;
      }[];
      bill_payment_at?: ISO8601DateFormatString;
    };
    check_ids?: number[];
  };
}

export const putReportExpenses = async ({ id, expenseParams }: PutReportExpensesParams) => {
  const res = await API.put('/web/report/expenses', {
    id,
    expense_params: JSON.stringify(expenseParams),
  });

  return res.data;
};

interface ReportWatcher {
  id: number;
  email: string;
  name: string | null;
  thumbnail_url: string | null;
  report_authority_id: number;
}

interface GetReportWatcherResponse {
  users: ReportWatcher[];
}

/**
 * GET /v4/reports/:id/report_authorities/watcher
 */
export const getReportWatcher = async (reportId: number) => {
  const res = await API.get<GetReportWatcherResponse>(`/v4/reports/${reportId}/report_authorities/watcher`);

  return res.data;
};

interface GetReportAddableWatchersParams extends Partial<PaginationParam> {
  filter?: {
    name?: string;
  };
}

interface GetReportAddableWatchersResponse {
  users: Omit<ReportWatcher, 'report_authority_id'>[];
  meta: {
    total_pages: number;
    total_count: number;
  };
}

/**
 * GET /v4/report_authority/addable_watchers
 * @see /apipie/4/report_authorities/addable_watchers.html
 */
export const getReportAddableWatchers = async (reportId: number, params: GetReportAddableWatchersParams) => {
  const res = await API.get<GetReportAddableWatchersResponse>(`/v4/report_authority/addable_watchers`, {
    params: {
      report_id: reportId,
      filter: {
        name: params.filter?.name,
      },
      page: params.page,
      count_per_page: params.countPerPage,
    },
  });

  return res.data;
};
/**
 * POST /v4/report_authority/watcher
 * @see /apipie/4/report_authorities/create_report_authority.html
 */
export const postReportWatcher = async (reportId: number, userId: number) => {
  const res = await API.post(`/v4/report_authority/watcher`, {
    report_id: reportId,
    user_id: userId,
  });

  return res.data;
};

/**
 * DELETE /v4/report_authorities/:id
 * @see /apipie/4/report_authorities/delete.html
 * @description report_authorities 도메인은 현재 Watcher만 사용되지만, 이후 추가될 수 있음. 추가되는 경우 API명 수정 필요
 */
export const deleteReportWatcher = async (watcherId: number) => {
  const res = await API.delete(`/v4/report_authorities/${watcherId}`);

  return res.data;
};

interface PostReportsErpOverviewParams {
  reportIds: number[];
  checkedAll: boolean;
  format: ExportType;
  page: number;
  filter: string | null;
}

/**
 * POST /web/reports/erp_overview
 */
export const postReportsErpOverview = async (params: PostReportsErpOverviewParams) => {
  const res = await API.post('/web/reports/erp_overview', {
    report_ids: params.reportIds,
    checked_all: params.checkedAll,
    format: params.format,
    page: params.page,
    filter: params.filter,
  });

  return res.data;
};

export interface GetCheckReportDenialParams {
  userId: number;
}

interface GetCheckReportDenialResponse {
  owner: boolean;
  is_policy_member: boolean;
}

/**
 * GET /v4/report/${reportId}/check_report_denial
 */
export const getCheckReportDenial = async (reportId: number, params: GetCheckReportDenialParams) => {
  const res = await API.get<GetCheckReportDenialResponse>(`/v4/report/${reportId}/check_report_denial`, {
    params: { user_id: params.userId },
  });

  return res.data;
};

interface PutReportDenyParams {
  id: number;
  comment: string;
  check_ids: number[];
  receiver: {
    id: number;
    user_id: number;
  };
}

interface PutReportDenyResponse {
  result: boolean;
}

/**
 * PUT /v4/report/deny
 */
export const putReportDeny = async (params: PutReportDenyParams) => {
  const res = await API.put<PutReportDenyResponse>('/v4/report/deny', params);

  return res.data;
};

interface PutEditorContentParams {
  url: string;
  content: string;
  cancelToken?: CancelToken;
}
/**
 * POST S3 URL에 내용을 업데이트
 * @param params
 * @param retry
 */
export const putEditorContent = (params: PutEditorContentParams, retry = 0) => {
  const file = new Blob([params.content], { type: 'text/html' });

  const putRequest = () => {
    return API.put(params.url, file, {
      headers: {
        'Content-Type': file.type,
      },
      cancelToken: params?.cancelToken,
    }).then(res => res.data);
  };

  return putRequest().catch(error => {
    /**
     * AWS의 S3 url을 이용해 너무 자주 요청을 하는 경우 503 에러가 발생할 수 있음. 이 경우 재시도
     * @see https://repost.aws/ko/knowledge-center/http-5xx-errors-s3
     */
    if (isAWSSlowDownErrorResponse(error.response) && retry === 0) {
      return putEditorContent({ url: params.url, content: params.content, cancelToken: null }, 1); // 최대 1회 재시도
    }
    throw error; // 재시도 횟수가 1이거나 다른 오류인 경우 에러 전파
  });
};

interface GetEditorUploadUrlResponse {
  upload_url: string;
}

/**
 * GET /v4/reports/:id/editor_content/upload_url
 * @param reportId
 */
export const getEditorContentUploadUrl = async (reportId: number) => {
  const res = await API.get<GetEditorUploadUrlResponse>(`/v4/reports/${reportId}/editor_content/upload_url`);

  return res.data;
};

export type PostChangeEditorTemplateParams = {
  templateId: number;
};

/**
 * POST /v4/reports/:id/editor_content/change_template
 * @param reportId
 * @param params
 */
export const postChangeEditorTemplate = async (reportId: number, params: PostChangeEditorTemplateParams) => {
  const res = await API.post(`/v4/reports/${reportId}/editor_content/change_template`, {
    report_editor_template_id: params.templateId,
  });

  return res.data;
};

interface PostUploadStatusResponse {
  report: Report;
}

/**
 * POST /v4/reports/:id/editor_content/upload_complete
 * @param reportId
 */
export const postUploadStatus = async (reportId: number) => {
  const res = await API.post<PostUploadStatusResponse>(`/v4/reports/${reportId}/editor_content/upload_complete`);

  return res.data;
};

/**
 * url은 서버에서 내려주는 S3 URL
 * @param url
 */
export const getEditorContentFormDownloadURL = async (url: string, cancelToken?: CancelToken) => {
  const res = await API.get(url, { cancelToken });
  const urlWithoutQuery = res.config.url.split('?')[0];
  const reportId = Number(urlWithoutQuery.split('/').pop());

  return {
    content: res.data,
    id: reportId,
  };
};

type GetReportsAwaitingApprovalResponse = {
  count: number;
};

/**
 * GET /v4/reports/awaiting_approval
 */
export const getReportsAwaitingApproval = async () => {
  const res = await API.get<GetReportsAwaitingApprovalResponse>('/v4/reports/awaiting_approval');

  return res.data;
};

export type PostReportsBulkApproveParams = {
  checkedAll?: boolean;
  reportIds?: number[];
  filter?: string;
};

export type ReportBulkApproveResult = {
  id: number;
  report_user_name: string;
  report_user_email: string;
  report_title: string;
  message: string;
  status: boolean;
};

export type PostReportsBulkApproveResponse = {
  total_count: number;
  success_count: number;
  result: ReportBulkApproveResult[];
};

const postReportsBulkApproveParamsSerializer = (params: PostReportsBulkApproveParams) => {
  if (params.checkedAll) {
    return { checked_all: true, exclude_ids: params.reportIds, filter: params.filter };
  }

  return { checked_all: false, include_ids: params.reportIds };
};

/**
 * POST /v4/reports/bulk_approve
 */
export const postReportsBulkApprove = async (params: PostReportsBulkApproveParams) => {
  const res = await API.post<PostReportsBulkApproveResponse>(
    '/v4/reports/bulk_approve',
    postReportsBulkApproveParamsSerializer(params)
  );

  return res.data;
};

export type GetReportAuthoritiesAddableWatcherUsersParams = {
  filter: { name: string };
  page: number;
  countPerPage: number;
};

export type GetReportAuthoritiesAddableWatcherUsersResponse = {
  meta: {
    total_count: number;
    total_pages: number;
  };
  users: WatchAddableUser[];
};

/**
 * GET /v4/report_authorities/addable_watcher_users
 */
export const getReportAuthoritiesAddableWatcherUsers = async (
  params: GetReportAuthoritiesAddableWatcherUsersParams
) => {
  const res = await API.get<GetReportAuthoritiesAddableWatcherUsersResponse>(
    '/v4/report_authorities/addable_watcher_users',
    {
      params: {
        filter: params.filter,
        page: params.page,
        count_per_page: params.countPerPage,
      },
    }
  );

  return res.data;
};

export type GetReportAuthoritiesAddableWatcherGroupsParams = {
  filter: {
    name: string;
  };
};

export type GetReportAuthoritiesAddableWatcherGroupsResponse = {
  groups: WatchAddableGroup[];
};

/**
 * GET /v4/report_authorities/addable_watcher_groups
 */
export const getReportAuthoritiesAddableWatcherGroups = async (
  params: GetReportAuthoritiesAddableWatcherGroupsParams
) => {
  const res = await API.get<GetReportAuthoritiesAddableWatcherGroupsResponse>(
    '/v4/report_authorities/addable_watcher_groups',
    { params }
  );

  return res.data;
};

export type GetReportsAuthoritiesWatchersResponse = {
  report_watchers: ReportWatcherV2[];
};

/**
 * GET /v4/reports/:id/report_authorities/watchers
 */
export const getReportsAuthoritiesWatchers = async (reportId: number) => {
  const res = await API.get<GetReportsAuthoritiesWatchersResponse>(
    `/v4/reports/${reportId}/report_authorities/watchers`
  );

  return res.data;
};

export type PutReportAuthoritiesWatchersParams = {
  reportWatchers: { watcher_type: ReportWatcherType; id: number; users?: { id: number }[] }[];
};

export type PutReportAuthoritiesWatchersResponse = {
  watchers: ReportWatcherV2[];
};

/**
 * PUT /v4/reports/:id/report_authorities/watchers
 */
export const putReportAuthoritiesWatchers = async (reportId: number, params: PutReportAuthoritiesWatchersParams) => {
  const res = await API.put<PutReportAuthoritiesWatchersResponse>(
    `/v4/reports/${reportId}/report_authorities/watchers`,
    {
      report_watchers: params.reportWatchers,
    }
  );

  return res.data;
};
