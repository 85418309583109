import '@mantine/core/styles/global.css';
import 'theme/stylesheets/reset.css';
import 'theme/stylesheets/about_us.css';
import 'theme/stylesheets/add-group-modal.css';
import 'theme/stylesheets/billing-transaction.css';
import 'theme/stylesheets/chart.css';
import 'theme/stylesheets/common.css';
import 'theme/stylesheets/company.css';
import 'theme/stylesheets/erp-account.css';
import 'theme/stylesheets/feed.css';
import 'theme/stylesheets/icon.css';
import 'theme/stylesheets/landing_nav.css';
import 'theme/stylesheets/mobile.css';
import 'theme/stylesheets/our_product.css';
import 'theme/stylesheets/overlay-trigger.css';
import 'theme/stylesheets/password-change-modal.css';
import 'theme/stylesheets/plan.css';
import 'theme/stylesheets/policy-approver-flow.css';
import 'theme/stylesheets/policy-members.css';
import 'theme/stylesheets/policy-refund.css';
import 'theme/stylesheets/policy-setting.css';
import 'theme/stylesheets/policy_upload_modal.css';
import 'theme/stylesheets/report.css';
import 'theme/stylesheets/report_plan.css';
import 'theme/stylesheets/scraping.css';
import 'theme/stylesheets/sign_in.css';
import 'theme/stylesheets/simple-select.css';
import 'theme/stylesheets/style.css';
import 'theme/stylesheets/tag.css';
import 'theme/stylesheets/transaction-upload.css';
import 'theme/stylesheets/user_card.css';
import 'theme/stylesheets/web-view.css';
import 'react-image-lightbox/style.css';
import '@mantine/core/styles.css';

import { DEFAULT_LANGUAGE, setLocale } from 'utils/LocaleUtil';
import { GlobalPrintPageStyle, GlobalStyle } from 'theme/globalStyle';
import { enableMapSet, setAutoFreeze } from 'immer';

import { AppModal } from '@N/view/components/WebView/AppModal';
import { CookiesProvider } from 'react-cookie';
import DatadogLogUtil from 'utils/DatadogLogUtil';
import { Entry } from '@N/view/bootstrap/Entry';
import GAUtil from 'utils/GAUtil';
import { MantineProvider } from '@mantine/core';
import { ModalManager } from '@N/view/components/Modal';
import { NuqsAdapter } from 'nuqs/adapters/react';
import { OktaSecurityProvider } from '@N/view/pages/SSO/Okta';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { QueryClientProvider } from 'react-query';
import React from 'react';
import ReactDOM from 'react-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Router } from 'react-router-dom';
import SentryUtil from './utils/SentryUtil';
import { ThemeProvider } from 'styled-components';
import applyPolyfills from '@N/view/bootstrap/config/polyfill';
import { attachNetworkStateListeners } from '@N/view/bootstrap/config/attachGlobalListeners';
import { configureAxios } from 'apis/API';
import configureJqueryAjax from '@N/view/bootstrap/config/configureJqueryAjax';
import configureQueryClient from '@N/view/bootstrap/config/configureQueryClient';
import { initAxiosConfig } from 'apis/bootstrap';
import { injectStoreToJQueryAPI } from 'components/web/api';
import { spenditHistory } from '@N/view/bootstrap/config/configureHistory';

if (process.env.REACT_APP_ENV === 'development.local') {
  /**
   * MSW Worker. 기본적으로 미사용. 필요할 때만 주석 해제하여 사용.
   */
  // mockWorker.start();
}

setLocale(DEFAULT_LANGUAGE).then(async () => {
  /**
   * @todo this.state를 직접 수정하는 코드들이 수정되면 제거
   */
  setAutoFreeze(false);
  enableMapSet();
  GAUtil.initialize();
  applyPolyfills();
  configureJqueryAjax();

  // Locale이 로드된 이후에 import
  const { TaskCardList } = await import('./components/commons/TaskCardList');
  const { ToastContainer } = await import('./components/commons/Toast/ToastContainer');
  const { defaultTheme, mantineTheme } = await import('theme');
  const { ErrorBoundary } = await import('@N/view/bootstrap/ErrorBoundary');
  const { LoadingSpinner } = await import('./components/utility/LoadingSpinner');
  const { configureStore } = await import('@N/view/bootstrap/config/configureStore');

  SentryUtil.initSentry();
  const { store, persistor } = configureStore();
  const { queryClient } = configureQueryClient();

  injectStoreToJQueryAPI(store);
  initAxiosConfig(store);
  configureAxios(store);
  attachNetworkStateListeners(store);

  const App = () => {
    return (
      <>
        <GlobalStyle />
        <GlobalPrintPageStyle />
        <ErrorBoundary>
          <CookiesProvider>
            <Provider store={store}>
              <ThemeProvider theme={defaultTheme}>
                <PersistGate loading={<LoadingSpinner />} persistor={persistor}>
                  <NuqsAdapter>
                    <Router history={spenditHistory}>
                      <QueryClientProvider client={queryClient}>
                        <MantineProvider theme={mantineTheme}>
                          <ModalManager.ModalsProvider modals={{ webView: AppModal }}>
                            <OktaSecurityProvider>
                              <Entry />
                            </OktaSecurityProvider>
                          </ModalManager.ModalsProvider>
                        </MantineProvider>
                        <ReactQueryDevtools initialIsOpen={false} />
                      </QueryClientProvider>
                    </Router>
                  </NuqsAdapter>
                  <ToastContainer />
                  <TaskCardList />
                </PersistGate>
              </ThemeProvider>
            </Provider>
          </CookiesProvider>
        </ErrorBoundary>
      </>
    );
  };

  const render = () => {
    ReactDOM.render(<App />, document.getElementById('root'));
  };

  DatadogLogUtil.init();
  render();
});
