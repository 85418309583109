import { SpenditError, SpenditErrorResponse } from 'apis/shared';

import API from 'apis/API';
import { CancelToken } from 'axios';
import { CompanyUserStatus } from 'apis/CompanyAPI';

/**
 * PUT /web/change_password
 */
export const updatePassword = async (params: UpdatePasswordParams) => {
  const res = await API.put<UpdatePasswordResponse>('/web/change_password', {
    user: {
      password: params.password,
      new_password: params.newPassword,
    },
  });
  return res.data;
};

/**
 * DELETE /v4/user/:id
 */
export const deleteUser = async ({ id }: DeleteUserParams) => {
  const res = await API.delete<{ id: number }>(`/v4/user/${id}`);

  return res.data;
};

/**
 * PUT /v4/users
 */
export const putUsers = async (id: number, params: PutUsersParam) => {
  const res = await API.put<ResponsePutUsers>(`/v4/users/${id}`, {
    email: params.email,
    name: params.name,
    language: params.language,
    role: params.role,
    erp_code: params.erpCode,
    web_hook_url: params.webHookUrl,
  });
  return res.data;
};

export type TokenType = 'invitation' | 'reset_password';

/**
 * GET /web/user/verify_token
 */
export const verifyToken = async (type: 'invitation' | 'reset_password', token: string) => {
  const res = await API.get('/web/user/verify_token', {
    params: {
      type,
      token,
    },
  });

  return res.data;
};

/**
 * POST /web/user/invite
 */
export const inviteUser = async (emails: string[], agreeAdditionalUser = false) => {
  const res = await API.post('/web/user/invite', { emails, agree_additional_user: agreeAdditionalUser });

  return res.data;
};

/**
 * GET /web/send_reset_password_link
 */
export const sendResetPasswordLink = async (email: string) => {
  const res = await API.get('/web/send_reset_password_link', {
    params: {
      email,
    },
  });
  return res.data;
};

export type SearchUsersByRoleResponse = {
  users: {
    id: number;
    email: string;
    name: string;
    company_name: string;
    thumbnail_url: string;
    status: CompanyUserStatus;
  }[];
};

/**
 * GET /v4/users/search_by_role
 */
export const searchUsersByRole = async (params: SearchUsersByRoleParams) => {
  const res = await API.get<SearchUsersByRoleResponse>('/v4/users/search_by_role', {
    params: {
      filter: {
        name: params.filter.name,
        initial: params.filter.initial,
        policy_id: params.filter.policyId,
        role: params.filter.role,
        status: params.filter.status,
        company_id: params.filter.companyId,
        group_id: params.filter.groupId,
      },
    },
  });

  return res.data;
};

/**
 * GET /v4/user/verify_invitation
 */
export const verifyInvitation = async (token: string) => {
  const res = await API.get<VerifyInvitationResponse>('/v4/user/verify_invitation', {
    params: {
      token,
    },
  });
  return res.data;
};

/**
 * POST /web/user/csv_invite
 */
export const postUserCSVInvite = async (params: PostUserCSVInviteParams) => {
  const formdata = new FormData();
  formdata.append('file', params.file);
  formdata.append('upload_type', params.uploadType);
  formdata.append('agree_additional_user', `${params.agreeAdditionalUser}`);
  const res = await API.post<PostUserCSVInviteResponse>('/web/user/csv_invite', formdata);

  return res.data;
};

/**
 * PUT /v4/user/confirm_invitation
 */
export const putUserConfirmInvitation = async (params: PutUserConfirmInvitationParams) => {
  const res = await API.put<PutUserConfirmInvitationResponse>('/v4/user/confirm_invitation', {
    token: params.token,
    name: params.name,
    password: params.password,
    device_token: params.deviceToken,
    device_type: params.deviceType,
    expres_at: params.expresAt,
    id: params.ip,
  });

  return res.data;
};

/**
 * POST /web/user/re_invite
 */
export const postUserReInvite = async (params: PostUserReInviteParams) => {
  const res = await API.post<PostUserReInviteParams>('/web/user/re_invite', params);

  return res.data;
};

/**
 * POST /web/user
 */
export const postUser = async (params: PostUserParams) => {
  const res = await API.post<PostUserResponse>('/web/user', {
    user: {
      name: params.name,
      email: params.email,
      password: params.password,
      device_type: params.deviceType,
      device_token: params.deviceToken,
    },
    ip: '',
  });

  return res.data;
};

/**
 * POST /web/session
 */
export const postSession = async (params: PostSessionParams, cancelToken?: CancelToken) => {
  const res = await API.post<PostSessionResponse>(
    '/web/session',
    {
      email: params.email,
      password: params.password,
      device_type: params.deviceType,
      device_token: params.deviceToken,
    },
    { cancelToken }
  );
  return res.data;
};

/**
 * DELETE /web/session
 */
export const deleteSession = async () => {
  const res = await API.delete('/web/session', {
    params: { ip: '' },
  });
  return res.data;
};

/**
 * PUT /web/user/change_policy
 */
export const putChangeUserPolicy = async (params: PutChangeUserPolicyParams) => {
  const res = await API.put<PutChangeUserPolicyResponse>('/web/user/change_policy', {
    user: { policy_id: params.user.policyId },
  });

  return res.data;
};

export type PutResetPasswordParams = {
  password: string;
  email: string;
  reset_token: string;
};

export type PutResetPasswordResponse = { result: boolean };

/**
 * PUT /web/reset_password
 */
export const putResetPassword = async (params: PutResetPasswordParams) => {
  const res = await API.put<PutResetPasswordResponse>('/web/reset_password', {
    user: params,
  });

  return res.data;
};

export type PutUsersDeactivatedResponse = {
  result: boolean;
};

export type PutUsersDeactivatedErrorResponse = SpenditErrorResponse<
  | SpenditError<40401, 'id'>
  | SpenditError<
      40901,
      'role',
      {
        errors: (
          | { type: 'deactivated' }
          | { type: 'company_owner' }
          | { type: 'policy_owner'; policy_names: string[] }
          | { type: 'default_tax_admin' }
        )[];
      }
    >
>;

/**
 * PUT /v4/users/:id/deactivated
 */
export const putUsersDeactivated = async (userId: number) => {
  const res = await API.put<PutUsersDeactivatedResponse>(`/v4/users/${userId}/deactivated`);

  return res.data;
};

export type PutUsersLiveResponse = {
  result: boolean;
};

/**
 * PUT /v4/users/:id/live
 */
export const putUsersLive = async (userId: number, agreeAdditionalUser = false) => {
  const res = await API.put<PutUsersLiveResponse>(`/v4/users/${userId}/live`, {
    agree_additional_user: agreeAdditionalUser,
  });

  return res.data;
};

export type PostUsersBulkDeactivatedParams = {
  includeIds: number[];
  preView: boolean;
};

export type PostUsersBulkDeactivatedResponse = {
  result: {
    successes: {
      id: number;
      email: string;
      name: string | null;
      thumbnail_url: string | null;
    }[];
    errors: {
      id: number;
      email: string;
      name: string | null;
      thumbnail_url: string | null;
      check_errors: (
        | { type: 'deactivated' }
        | { type: 'company_owner' }
        | { type: 'policy_owner'; policy_names: string[] }
        | { type: 'default_tax_admin' }
      )[];
    }[];
  };
};

/**
 * POST /v4/users/bulk_deactivated
 */
export const postUsersBulkDeactivated = async (params: PostUsersBulkDeactivatedParams) => {
  const res = await API.post<PostUsersBulkDeactivatedResponse>('/v4/users/bulk_deactivated', {
    include_ids: params.includeIds,
    pre_view: params.preView,
  });

  return res.data;
};

type PostUsersBulkLiveParams = {
  include_ids: number[];
  agree_additional_user: boolean;
};

type PostUsersBulkLiveResponse = PostUsersBulkDeactivatedResponse;

/**
 * POST /v4/users/bulk_live
 */
export const postUsersBulkLive = async (params: PostUsersBulkLiveParams) => {
  const res = await API.post<PostUsersBulkLiveResponse>('/v4/users/bulk_live', params);

  return res.data;
};

type PutDelayTemporaryPasswordChangeResponse = {
  result: boolean;
};

/**
 * PUT /v4/user/delay_temporary_password_change
 */
export const putDelayTemporaryPasswordChange = async () => {
  const res = await API.put<PutDelayTemporaryPasswordChangeResponse>('/v4/user/delay_temporary_password_change');

  return res.data;
};

export interface GetUserCompanyDefaultCodeParams {
  default_code: string;
}

//default_code가 중복되는지 여부 [true: 중복, false: 중복아님]
interface GetUserCompanyDefaultCodeResponse {
  result: boolean;
}

/**
 * GET /v4/users/company/default_code
 */
export const getUserCompanyDefaultCode = async (params: GetUserCompanyDefaultCodeParams) => {
  const res = await API.get<GetUserCompanyDefaultCodeResponse>('/v4/users/company/default_code', { params });

  return res.data;
};

export interface PutUserCompanyDefaultCodeParams {
  default_code: string;
  user_id: number;
}

// 성공 여부
interface PutUserCompanyDefaultCodeResponse {
  result: boolean;
}

/**
 * PUT /v4/users/company/default_code
 */
export const putUserCompanyDefaultCode = async (params: PutUserCompanyDefaultCodeParams) => {
  const res = await API.put<PutUserCompanyDefaultCodeResponse>('/v4/users/company/default_code', params);

  return res.data;
};

export default {
  updatePassword,
  deleteUser,
  putUsers,
  verifyToken,
  inviteUser,
  sendResetPasswordLink,
  verifyInvitation,
  postUserCSVInvite,
  putUserConfirmInvitation,
  postUserReInvite,
  postUser,
  postSession,
  putResetPassword,
  getUserCompanyDefaultCode,
  putUserCompanyDefaultCode,
};
